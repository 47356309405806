const dataShow = [
    {
        path: '/',
        component: () => import('@/layout/index.vue'),
        meta: {
            title: '数据看板',
            isMenu: true,
            icon: 'icon_dataShow.png',
            headTitle: true,
        },
        redirect:'/statistics/dataShow',
        children: [
            {
                path:'/statistics/dataShow',
                component: () => import('@/views/dataShow/index.vue'),
                meta: {
                    title: '数据看板',
                    isMenu: true,
                },
            }
        ]
    }
]

export default dataShow