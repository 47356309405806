const operation = [
  {
    path: '/operation',
    component: () => import('@/layout/index.vue'),
    meta: {
      title: '运营管理',
      isMenu: true,
      icon: 'icon_operation.png',
      showChild:true
    },
    children: [
      {
        path: '/operation/snowResort',
        component: () => import('@/views/snowResort/index.vue'),
        meta: {
          title: '雪场管理',
          keepAlive: false,
          isMenu: true,
        },
      },
      {
        path: '/operation/goods',
        component: () => import('@/views/goods/index.vue'),
        meta: {
          title: '商品管理',
          keepAlive: false,
          isMenu: true,
        },
      },
      {
        path: '/operation/orders',
        component: () => import('@/views/orders/index.vue'),
        meta: {
          title: '订单管理',
          keepAlive: false,
          isMenu: true,
        },
      },
    ],
  },
]

export default operation