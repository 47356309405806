import axios from "axios";

/**
 * 加载图片
 * @param {String} id 图片的id或者url
 * @param {number} width 当传递了width的时候，可以对图片进行
 * @param {number} height
 */
export function loadImg(id, width, height) {
    if (!id) return "";
    let url = id.startsWith("http")
        ? id
        : `${process.env.VUE_APP_URL}/index/attachment/view/${id}`;
    if (width || height) {
        // 阿里云oss图片切割
        const prefix = url.includes("?") ? "&" : "?";
        url += `${prefix}x-oss-process=image/resize,m_fill,h_${height},w_${width}`;
    }
    return url;
}

/**
 * 下载附件
 * */

export function downLoad(url,config) {
    return new Promise((resolve, reject) => {
        let params=JSON.parse(JSON.stringify(config))
        Object.keys(params).forEach(key=>{
            if(params[key]==='全部'){
                delete params[key]
            }
        })
        axios.request({
            method:'post',
            url:`${process.env.VUE_APP_URL}${url}`,
            responseType:'blob',
            data : params
        }).then(res=>{
            resolve()
            const blob=new Blob([res.data],{
                type:res.headers['content-type']
            })
            let file=res.headers['content-disposition'].split('filename=')[1]
            const link=document.createElement('a');
            link.download=decodeURIComponent(file);
            link.href=URL.createObjectURL(blob);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(link.href)
        })
    })
}