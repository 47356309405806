import axios from "axios";
// import { Loading, Message } from "element-plus";

// 定义 loading
let loading;

function startLoading() {
    // element-ui loading 服务调用方式
    // loading = Loading.service({
    //     lock: true,
    //     text: "拼命加载中...",
    //     spinner: "el-icon-loading", // 自定义图标
    //     background: "rgba(0, 0, 0, 0.7)",
    // });
}

// loading结束 方法
function endLoading() {
    // loading.close();
}

const DEFAULT_LOADING = true;

class Service {
    constructor(config) {
        this.instance = axios.create(config);
        // this.showLoading = config.showLoading ?? DEFAULT_LOADING;
        /*实例公共的请求拦截*/
        this.instance.interceptors.request.use((config) => {
            return config;
        });
        /*实例公共的响应拦截*/
        this.instance.interceptors.response.use(
            (response) => {
                // endLoading();
                return response;
            },
            (error) => {
                let {
                    response: {status},
                } = error;
                // endLoading();
                errorFunction(status);
                return error;
            }
        );

        /*实例特有的请求拦截*/
        this.instance.interceptors.request.use(
            config.interceptors?.requestInterceptor,
            config.interceptors?.requestInterceptorCatch
        );
        /*实例特有的响应拦截*/
        this.instance.interceptors.response.use(
            config.interceptors?.responseInterceptor,
            config.interceptors?.responseInterceptorCatch
        );
    }

    request(config) {
        return new Promise((reslove, reject) => {
            // if (config.showLoading === false) {
            //   this.showLoading = false;
            // }
            this.instance
                .request(config)
                .then((res) => {
                    // this.showLoading = DEFAULT_LOADING;
                    reslove(res);
                })
                .catch((error) => {
                    // this.showLoading = DEFAULT_LOADING;
                    reject(error);
                });
        });
    }

    get(config) {
        return this.request({
            ...config,
            method: "get",
        });
    }

    post(config) {
        return this.request({
            ...config,
            method: "post",
        });
    }
}

export default Service;

/**
 *
 * @param {number} status
 */
function errorFunction(status) {
    switch (status) {
        case 404:
            // Message.error("接口不存在");
            break;
        case 500:
            // Message.error("服务器错误");
            break;
        default:
            // Message.error("请求异常~");
            localStorage.clear();
            break;
    }
}
