import Service from "@/service/request/index.js";
import cache from "@/utils/cache";
import { ElNotification } from 'element-plus'
import  router from '@/router'
import {showElNotification} from "@/utils/element";

// 获取最终URL
const BaseURL = process.env.VUE_APP_URL.endsWith("/")
    ? process.env.VUE_APP_URL
    : process.env.VUE_APP_URL + "/";

const IMG_URL = `${BaseURL}index/attachment/view/`;

/**
 * 创建一个实例
 */
const jyRequest = new Service({
    baseURL: BaseURL,
    showError:true,
    /*实例特有的请求和响应拦截*/
    interceptors: {
        /*请求成功拦截*/
        requestInterceptor: (config) => {
            const token=cache.getItem('token')
            if(token){
                config.headers.Token=token
            }
            if(typeof config.data==='object'&&config.data){
                Object.keys(config.data).forEach(key=>{
                    if(config.data[key]==='全部'){
                        delete config.data[key]
                    }
                })
            }
            config.headers['X-Requested-With']='XMLHttpRequest';
            return config;
        },
        /*请求失败拦截*/
        requestInterceptorCatch: (error) => {
            return error;
        },
        /*响应成功拦截*/
        responseInterceptor: (response) => {
            let data = response.data;
            if (data.error) {
                codeFunction(response);
                return Promise.reject(data.message);
            } else {
                return Promise.resolve(data.data);
            }
        },
        /*响应失败拦截*/
        responseInterceptorCatch: (error) => {
            return error;
        },
    },
});

function codeFunction(res) {
    let {
        data: { message, code },
    } = res;
    if (code ==='300') {
        // 跳到登录页面去
        localStorage.clear();
       router.replace({
           path:'/login'
       })
    }
    if(res.config.showError){
        showElNotification({message})
    }
}

/**
 * 接口实例用法同axios*/
export { jyRequest, IMG_URL, BaseURL };
